import { UnwagerGameList } from "./unwager-game-list";

export function Info() {
  return (
    <div className="lg:max-h-full flex flex-col min-h-0 h-full mobile-only:relative">
      <div className="lg:overflow-y-auto scrollbar-thin gutter-stable">
        <UnwagerGameList />
      </div>
    </div>
  );
}
