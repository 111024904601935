import { emitter } from "@lobby/core/shared";
import { clsx } from "clsx";

interface ISupportChatBtnProps {
  className?: string;
  hasUnreadMessages: boolean;
  onClick?: VoidFunction;
}

export function SupportChatBtn({ className, hasUnreadMessages, onClick }: ISupportChatBtnProps) {
  function handleClick() {
    emitter.emit("SUPPORT_CHAT_OPEN");
    onClick?.();
  }

  return (
    <button
      className={clsx("support-chat-btn group", className)}
      type="button"
      onClick={handleClick}
    >
      <svg
        className="fill-white group-hover:fill-keppel dark:fill-mine-shaft dark:group-hover:fill-mine-shaft"
        width="100%"
        height="100%"
        viewBox="0 0 54 50"
      >
        <path
          className="fill-keppel group-hover:fill-white stroke-keppel dark:fill-java dark:stroke-java group-hover:dark:stroke-white"
          d="m49.8 21.83 3.87 23.52A4 4 0 0 1 49.72 50H25A25 25 0 1 1 49.5 20c.12.6.22 1.21.3 1.83Z"
        />
        <path
          fill="current"
          d="M25 9C16.18 9 9 16.18 9 25v6.63c0 1.64 1.44 2.97 3.2 2.97h1.6c.88 0 1.6-.72 1.6-1.6v-8.23c0-.88-.72-1.6-1.6-1.6h-1.45A12.8 12.8 0 0 1 25 12.2a12.8 12.8 0 0 1 12.65 10.97H36.2c-.88 0-1.6.72-1.6 1.6v9.83a3.2 3.2 0 0 1-3.2 3.2h-3.2v-1.6h-6.4V41H31.4a6.4 6.4 0 0 0 6.4-6.4c1.76 0 3.2-1.33 3.2-2.97V25c0-8.82-7.18-16-16-16Z"
        />
        <path
          fill="current"
          d="m16.84 30 .33-1.54a177.43 177.43 0 0 0 2.87-2.1c.42-.32.75-.64 1-.93.26-.3.42-.6.49-.92.07-.3.02-.55-.14-.74-.15-.2-.41-.3-.77-.3-.18 0-.36.03-.56.09a7.63 7.63 0 0 0-1.88.88l.27-2.29a9.07 9.07 0 0 1 1.25-.42 6.46 6.46 0 0 1 1.5-.18c.7 0 1.27.13 1.71.38.45.25.76.6.94 1.04.18.43.2.93.09 1.49-.1.4-.25.79-.47 1.14-.23.35-.53.7-.93 1.03-.39.34-.88.7-1.48 1.08l-1.26.82-.24-.5h3.67L22.82 30h-5.98Zm10.62 0a271.5 271.5 0 0 1 .53-2.48l.24-.3.44-2.11a313.66 313.66 0 0 1 .5-2.42l1.73-.75a357.88 357.88 0 0 0-1.5 1.67l-1.6 1.78a130.42 130.42 0 0 0-1.3 1.44l-.08-.28h2.35l.23-.16H31.53l-.4 1.97H24.23l.31-1.53.65-.72.72-.8.67-.75 1.25-1.39.64-.72.65-.72h2.38a1161.6 1161.6 0 0 0-.6 2.89l-.6 2.89a1652.12 1652.12 0 0 0-.53 2.49h-2.32Z"
        />
      </svg>
      {hasUnreadMessages && (
        <sup className="absolute -top-1 right-0 size-4 rounded-full bg-radical-red" />
      )}
    </button>
  );
}
