import { apiClient } from "@lobby/api-client";
import { queryClient } from "@lobby/core/app";
import { ensureUserIsAuthenticated } from "./helpers";
import type { ApiClientResponse } from "@lobby/api-client";
import { checkMobile as isMobile } from "@lobby/core/shared";

export async function loadFortuneWheelSettings() {
	const isAuthenticated = await ensureUserIsAuthenticated();
	if (isAuthenticated) {
		return queryClient.ensureQueryData<
			ApiClientResponse<"FortuneWheel.getSettings">
		>({
			queryKey: ["FortuneWheel.getSettings"],
			queryFn: () =>
				apiClient.send({
					endpoint: "FortuneWheel.getSettings",
				}),
		});
	}
}

export function loadLobbyConfig() {
	return queryClient.ensureQueryData<ApiClientResponse<"Lobby.getCurrent">>({
		queryKey: ["Lobby.getCurrent"],
		queryFn: () =>
			apiClient.send({
				endpoint: "Lobby.getCurrent",
			}),
	});
}

export function loadAuthEndpoints() {
	return queryClient.ensureQueryData<
		ApiClientResponse<"SocialNetwork.getAuthEndpoints">
	>({
		queryKey: ["SocialNetwork.getAuthEndpoints"],
		queryFn: () =>
			apiClient.send({
				endpoint: "SocialNetwork.getAuthEndpoints",
			}),
	});
}

export function loadBannerList() {
	const lang = localStorage.getItem("locale") ?? "en";
	const deviceType = isMobile() ? "mobile" : "desktop";

	return queryClient.ensureQueryData<ApiClientResponse<"Slider.getSimplified">>(
		{
			queryKey: ["Slider.getSimplified", lang, deviceType],
			queryFn: () =>
				apiClient.send({
					endpoint: "Slider.getSimplified",
					params: {
						name: "test",
						deviceType,
						lang,
					},
				}),
		},
	);
}

export function loadGameCategoryList() {
	const lang = localStorage.getItem("locale") ?? "en";
	return queryClient.ensureQueryData<
		ApiClientResponse<"Game.getCategoriesList">
	>({
		queryKey: ["Game.getCategoriesList", lang],
		queryFn: () =>
			apiClient.send({
				endpoint: "Game.getCategoriesList",
				params: { lang },
			}),
	});
}

export function loadGameList() {
	return queryClient.ensureQueryData<ApiClientResponse<"Game.getList">>({
		queryKey: ["Game.getList"],
		queryFn: () => apiClient.send({ endpoint: "Game.getList" }),
	});
}

export function loadProviderList() {
	return queryClient.ensureQueryData<
		ApiClientResponse<"Game.getProvidersList">
	>({
		queryKey: ["Game.getProvidersList"],
		queryFn: async () => apiClient.send({ endpoint: "Game.getProvidersList" }),
	});
}

export function loadRecommendedGames() {
	return queryClient.ensureQueryData<
		ApiClientResponse<"Game.getRecommendations">
	>({
		queryKey: ["Game.getRecommendations"],
		queryFn: () => apiClient.send({ endpoint: "Game.getRecommendations" }),
	});
}
