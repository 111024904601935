import { useTranslate } from "@lobby/ocb-intl";
import { Logo, SVGIcon } from "@shared/ui";
import { Link } from "@tanstack/react-router";
import { clsx } from "clsx";

export function Footer({ className }: { className?: string }) {
  const { $t } = useTranslate();

  return (
    <footer className={clsx(className, "relative bg-athens-gray dark:bg-outer-space")}>
      <div className="mx-auto p-3 lg:max-w-screen-3xl lg:p-5">
        <div className="flex flex-col gap-2 lg:contents">
          <div className="flex-c-sb gap-3 lg:gap-5">
            <Link className="shrink-0" to="/">
              <Logo className="h-5 lg:h-11" />
            </Link>

            <div
              className="flex-center cursor-pointer gap-1 whitespace-nowrap font-medium text-2xs text-keppel lg:gap-2.5 lg:text-base dark:text-java"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              <span className="hidden">
                {$t({ defaultMessage: "18+" })}
                {$t({
                  defaultMessage:
                    "Gambling can be addictive. Play responsibly. {themeName} only accept customers over 18 years of age.",
                })}
                {$t({ defaultMessage: "game" })}
                {$t({ defaultMessage: "responsibly" })}
              </span>
              <span>{$t({ defaultMessage: "Back to top" })}</span>
              <button
                className="size-5 flex-center rounded-rounded bg-mercury lg:size-8 lg:hover:text-outer-space dark:bg-ebony-clay lg:dark:hover:text-white"
                type="button"
              >
                <SVGIcon className="-rotate-90 size-1.5 lg:size-3" name="arrowRight" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
