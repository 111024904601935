import fortuneWheelBtnImg from "@assets/fw-btn-bg.png";
import { FortuneWheel, Lobby } from "@lobby/core/entities";
import {
  type TFortuneWheelSettings,
  formatTime,
  useFortuneWheelCountdown,
} from "@lobby/core/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { Link } from "@tanstack/react-router";

export function FortuneWheelButton() {
  const { $t } = useTranslate();
  const { data: lobbyData } = Lobby.useLobby();
  const { data: settingsData } = FortuneWheel.useWheelSettings();

  const countdown = useFortuneWheelCountdown(lobbyData?.fortuneWheel?.nextSpinTime as number);

  const settings = settingsData?.result;
  const isCountdownActive = countdown > 0;

  function isDepositAbsent(settings: TFortuneWheelSettings) {
    return settings.state === "make_deposit";
  }

  if (settingsData?.error) {
    return null;
  }

  return (
    <div className="relative mt-5 h-[5.625rem] overflow-hidden">
      <div className="-translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2">
        <img
          className="size-36 animate-step-spin object-contain"
          src={fortuneWheelBtnImg}
          alt="fortune-wheel-preview"
          width="100%"
          height="100%"
        />
      </div>
      <Link
        className={`${isCountdownActive ? "bg-midnight-express" : "bg-black"} -translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 min-w-44 rounded-rounded border-2 border-java px-2.5 py-1 hover:drop-shadow-[0_0_0.25rem_#13c7bc]`}
        to="/fortune-wheel"
      >
        <div className="whitespace-nowrap text-center font-commissioner font-extrabold text-java uppercase italic [text-shadow:0_0_1.25rem_#13c7bc]">
          {isCountdownActive
            ? formatTime(countdown)
            : isDepositAbsent(settings ?? ({} as TFortuneWheelSettings))
              ? $t({ defaultMessage: "fortune wheel" })
              : $t({ defaultMessage: "ready to spin" })}
        </div>
      </Link>
      <hr className="absolute top-0 left-0 h-1 w-full border-none [background-image:linear-gradient(to_right,transparent,#13c7bc_35%,#13c7bc_65%,transparent)]" />
      <hr className="absolute bottom-0 left-0 h-1 w-full border-none [background-image:linear-gradient(to_right,transparent,#13c7bc_35%,#13c7bc_65%,transparent)]" />
    </div>
  );
}
