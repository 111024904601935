import { PlayerInfo } from "@features/player/player-info";
import { TakeBonusButton } from "@features/take-bonus-button";
import { Game, Player } from "@lobby/core/entities";
import { APIError, CLIENT_ERRORS, emitter, useMobile } from "@lobby/core/shared";
import { FloatGroup } from "@shared/ui";
import { Logo } from "@shared/ui/logo";
import { SVGIcon } from "@shared/ui/svg-icon";
import { onlineManager } from "@tanstack/react-query";
import { Link, useNavigate, useParams } from "@tanstack/react-router";
import { Header } from "@widget/header";
import { JackpotTickers } from "@widget/jackpot-ticker-slots";
import { MiniGame, MiniGameContextProvider } from "@widget/mini-game";
import { useEffect, useRef, useState } from "react";
import { isGameExistInHeader as checkGameExistInHeader } from "../lib/helpers";
import { GamePageControlsMobile } from "./game-page-controls.mobile";

import "./styles.css";

export function GamePage() {
  // @ts-ignore
  const { gameId: gameStringId } = useParams({ from: "/_auth/game/$gameId" });
  const navigate = useNavigate();
  const [iframeKey, setIframeKey] = useState<string>(Date.now().toString());
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const isMobile = useMobile();

  const { data: player } = Player.usePlayer();
  const { data: categoriesList } = Game.useCategoriesList();

  const theme = (localStorage.getItem("theme") as "light" | "dark") ?? "dark";

  const { data } = Game.useGameRun({
    gameStringId,
    place: "game_list",
    lang: localStorage.getItem("locale") ?? "en",
    deviceType: isMobile ? "mobile" : "desktop",
    theme,
    color: theme === "dark" ? "13c7bc" : "43ada8",
  });

  const gameUrl = data?.result?.url;

  const isGameExistInHeader =
    categoriesList && checkGameExistInHeader(categoriesList, gameStringId);

  const reloadIframe = () => {
    if (iframeRef.current) {
      setIframeKey(Date.now().toString());
    }
  };

  useEffect(() => {
    if (data?.error) {
      const error = data.error;
      navigate({ to: "/casino", replace: true, search: true });

      const apiError = new APIError(error.message, {
        code: error.code,
        game: gameStringId,
        PID: player?.id ? `${player.id} / ${player.hallId}` : null,
      });

      emitter.emit("ERROR_MODAL_OPEN", apiError);
    }
  }, [data, navigate, gameStringId, player]);

  useEffect(() => {
    const unsubThemeChange = emitter.on("THEME_CHANGED", reloadIframe);
    const unsubLocaleChange = emitter.on("SET_LOCALE", reloadIframe);

    if (!onlineManager.isOnline()) {
      emitter.emit("ERROR_MODAL_OPEN", new Error(CLIENT_ERRORS[0]));
    }

    return () => {
      onlineManager.subscribe((isOnline) => {
        if (!isOnline) {
          emitter.emit("ERROR_MODAL_OPEN", new Error(CLIENT_ERRORS[0]));
        }
      });

      unsubThemeChange();
      unsubLocaleChange();
    };
  }, []);

  return (
    <>
      <MiniGameContextProvider>
        <MiniGame />
      </MiniGameContextProvider>
      <div className="game-frame fixed inset-0 z-[100] h-dvh w-screen overflow-y-hidden">
        <div className="h-full">
          <div className="flex h-full flex-col">
            {isGameExistInHeader ? <Header /> : <GamePageHeader gameStringId={gameStringId} />}
            <div className="grow">
              {gameUrl && (
                <iframe
                  key={iframeKey}
                  ref={iframeRef}
                  className="size-full"
                  id="game-frame"
                  title="game-frame"
                  src={gameUrl}
                  allow="fullScreen"
                  allowFullScreen
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <FloatGroup>
        <TakeBonusButton />
      </FloatGroup>
    </>
  );
}

function GamePageHeader({ gameStringId }: { gameStringId: string }) {
  const isMobile = useMobile();
  const navigate = useNavigate();

  // const game = Game.getGameByStringId(gameStringId);
  // const redeemProviderBalance = Game.useRedeemProviderBalance();

  function onGameClose() {
    // if (game?.isWalletIntegration) {
    //   redeemProviderBalance.mutate({ gameStringId });
    // }
  }

  if (isMobile) {
    return (
      <div className="relative mobile-only:p-0.5 p-2.5">
        <JackpotTickers compact />
        <GamePageControlsMobile gameStringId={gameStringId} onGameClose={onGameClose} />
      </div>
    );
  }

  return (
    <div className="flex items-center justify-between px-5 py-3">
      <Link to="/">
        <Logo className="h-7 lg:h-11" />
      </Link>
      <JackpotTickers className="game-frame__jackpot-tickers" />
      <div className="flex h-full items-center gap-5">
        <PlayerInfo />
        <button
          type="button"
          onClick={() => {
            onGameClose();
            navigate({ to: "/casino" });
          }}
        >
          <SVGIcon
            className="size-4 text-keppel hover:text-java lg:size-8 dark:text-java dark:hover:text-keppel"
            name="close"
          />
        </button>
      </div>
    </div>
  );
}
