import { clsx } from "clsx";

export function Logo({ className }: { className?: string }) {
  return (
    <div className={clsx(className, "[aspect-ratio:225/43] *:size-full")}>
      <svg
        className="text-keppel dark:text-java"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 225 43"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="m159.363 27.886-1.239 8.754h6.297l1.55-10.955a8.612 8.612 0 0 0-2.267.234c-1.406.326-2.738.949-3.829 1.63-.18.113-.351.226-.512.337Zm.484-3.428a14.886 14.886 0 0 1 3.243-1.183 11.211 11.211 0 0 1 3.262-.288l1.977-13.976h4.994l.652-4.451H157.69l-.651 4.45h4.994l-2.186 15.448Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="m134.568 36.64 4.56-32.08h14.765l-.652 4.45h-8.196l-1.195 8.577h6.948l-.705 4.94h-6.948l-1.357 9.77h8.196l-.651 4.343h-14.765ZM110.345 36.64l4.505-32.08h11.399c2.027 0 3.511.488 4.452 1.465.94.941 1.284 2.262 1.031 3.963l-.489 3.637a8.748 8.748 0 0 1-.814 2.605 6.04 6.04 0 0 1-1.465 1.954 5.708 5.708 0 0 1-1.738.923 6.545 6.545 0 0 1-1.737.38l-.054.055c.615 0 1.194.072 1.737.217a3.576 3.576 0 0 1 1.574.868c.507.47.887 1.122 1.14 1.954.254.796.29 1.792.109 2.986l-.543 4.397c-.253 1.737-.76 3.094-1.52 4.07a6.079 6.079 0 0 1-2.877 2.064c-1.122.361-2.443.542-3.962.542h-10.748Zm6.785-3.31h2.931c.543 0 .995-.091 1.357-.272.398-.217.742-.597 1.032-1.14.289-.543.506-1.34.651-2.389l.543-3.8c.217-1.447.09-2.388-.38-2.822-.434-.47-1.05-.706-1.846-.706h-2.714L117.13 33.33Zm2.117-15.091h2.606c.832 0 1.519-.29 2.062-.869.579-.579.959-1.592 1.14-3.04l.489-3.202c.181-1.122.09-1.918-.272-2.389-.362-.506-.868-.76-1.52-.76h-3.094l-1.411 10.26Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M202.751 42.069c11.992 0 21.713-9.722 21.713-21.713C224.464 8.364 214.066 0 203.43 0c-6.046 0-11.535 2.714-15.02 5.59-.442.16-1.061.26-1.706.364-.941.152-1.936.313-2.517.686l-.003-.002c-1.034.665-1.905 1.498-2.777 2.333-.561.536-1.122 1.073-1.728 1.567-1.243 1.013-2.249 1.15-3.634 1.25-.161.013-.211.183-.092.286 1.325 1.158 3.075 2.127 4.817 2.161-2.855 3.498-5.001 3.327-7.59 3.121-1.373-.11-2.872-.229-4.666.186-5.192 1.199-8.999 4.612-11.882 8.676-.067.094.04.207.154.165l.115-.043a15.48 15.48 0 0 1 1.536-1.091c1.284-.803 2.892-1.565 4.653-1.974 1.764-.41 3.727-.474 5.664.186 1.953.665 3.767 2.029 5.271 4.285l-1.574 1.049.017.014c.603.5 1.228 1.018 1.631 1.316 1.986 1.468 4.872 1.732 7.284.926.121-.04.228.083.147.175-1.309 1.455-3.002 2.521-5.08 2.327-.204-.019-.406-.04-.608-.06-.98-.102-1.938-.202-2.92-.044-1.108.179-1.925.738-2.55 1.553-.063.083.022.196.131.172 1.358-.301 2.858.498 4.1 1.16h.001c.25.134.49.262.716.374.459.227.904.476 1.349.724.212.12.424.238.638.354 1.73.943 5.026.956 7.007-.034.243-.121.48-.243.713-.363 1.039-.534 1.99-1.022 2.89-1.178.162.152.326.301.493.448-.643 1.074-1.182 1.587-2.245 2.596l-.016.016c-.63.597-1.846 1.199-3.26 1.297-.121.008-.17.147-.069.207 2.51 1.476 6.909 2.129 10.353 2.129 2.635 0 5.099-.337 7.364-.947.728.074 1.467.112 2.214.112Zm.679-40.712c-10.873 0-19.678 8.805-19.678 19.677 0 2.388.434 4.68 1.205 6.794v.257l.209.239c2.882 7.257 9.97 12.388 18.264 12.388 10.872 0 19.677-8.805 19.677-19.678 0-10.872-8.805-19.677-19.677-19.677Zm-16.001 13.29a17.146 17.146 0 0 0-1.202 5.26l5.444 1.882 3.632 9.37-3.254 2.79c3.046 2.684 7.017 4.31 11.381 4.31 4.509 0 8.616-1.737 11.69-4.577l-8.58 3.663-5.456-5.682 6.739-7.582 9.21 1.137-1.502 8.072a17.233 17.233 0 0 0 5.111-11.164l-1.662-.415-3.109-8.759.706-3.05a17.271 17.271 0 0 0-6.605-4.809l-4.404-.56-1.964-.723h-.174a17.173 17.173 0 0 0-10.451 3.542l-1.11 1.788-4.44 5.507Zm23.866-3.91-6.971-6.5-9.542 3.429.329 9.255 10.477 1.978 5.707-8.163Z"
          clipRule="evenodd"
        />
        <path
          fill="#2E3641"
          d="M223 21c0 10.866-8.809 19.675-19.675 19.675-10.866 0-19.675-8.809-19.675-19.675 0-10.866 8.809-19.675 19.675-19.675C214.191 1.325 223 10.134 223 21Z"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M223.107 21.034c0-10.872-8.805-19.677-19.677-19.677-10.873 0-19.678 8.805-19.678 19.677a19.643 19.643 0 0 0 1.205 6.794v.257l.174.198c2.65 6.685 8.858 11.572 16.287 12.327-7.414-.753-13.606-5.623-16.252-12.286l-.035-.04a19.797 19.797 0 0 1-1.379-7.25c0-10.867 8.81-19.677 19.678-19.677 10.867 0 19.677 8.81 19.677 19.677ZM203.43 40.712c10.782 0 19.531-8.66 19.675-19.407-.144 10.742-8.898 19.407-19.675 19.407Zm-17.203-20.805a17.147 17.147 0 0 1 1.202-5.26l4.44-5.507 1.11-1.788A17.173 17.173 0 0 1 203.43 3.81h.174l1.964.723 4.404.56a17.264 17.264 0 0 1 6.605 4.809l-.706 3.05 3.109 8.759 1.662.415a17.233 17.233 0 0 1-5.111 11.164l1.502-8.072-9.21-1.137-6.739 7.582 5.456 5.682 8.581-3.663a17.176 17.176 0 0 1-11.691 4.576c-4.364 0-8.335-1.625-11.381-4.309l3.254-2.79-3.632-9.37-5.444-1.882Zm18.097-15.67 6.971 6.499-5.707 8.163-10.477-1.978-.329-9.255 9.542-3.43Z"
          clipRule="evenodd"
        />
        <path
          className="fill-outer-space dark:fill-white"
          fill="#ffffff"
          d="M85.598 37 95.832 5h9.042l1.083 32h-7.039l-.108-5.902h-4.657L92.367 37h-6.769Zm9.043-9.042h4.494l.27-17.381-4.764 17.38ZM62.753 37l4.549-32h11.695c2.13 0 3.664.487 4.602 1.462.975.938 1.318 2.256 1.03 3.953l-.65 4.98c-.253 1.698-.921 3.123-2.004 4.278-1.047 1.155-2.527 1.823-4.44 2.004 1.191.18 2.004.595 2.437 1.245.433.614.74 1.625.92 3.032L82.354 37H75.37l-.758-10.613a9.696 9.696 0 0 0-.216-1.57c-.109-.469-.271-.83-.488-1.083-.18-.288-.45-.433-.812-.433h-1.84L69.304 37h-6.552Zm9.097-18.03h2.599c.938 0 1.678-.271 2.22-.813.541-.541.902-1.462 1.083-2.761l.541-3.844c.18-1.155.09-1.968-.27-2.437-.361-.47-.885-.704-1.57-.704h-3.141L71.85 18.97ZM46.492 37 50.39 9.44H45.41l.65-4.44h16.243l-.65 4.44h-4.981L52.773 37h-6.281ZM19.516 37l7.093-16.46L24.335 5h6.28l1.3 10.667L36.192 5h6.606L36.03 20.54 38.142 37H31.86l-1.408-10.667L26.23 37h-6.714ZM1 37 5.548 5h14.728l-.65 4.44H11.45l-1.191 8.555h6.93l-.703 4.927H9.555l-1.354 9.746h8.176L15.727 37H1Z"
        />
      </svg>
    </div>
  );
}

export function LogoSecond({ className }: { className?: string }) {
  return (
    <div className={clsx(className, "[aspect-ratio:225/43] *:size-full")}>
      <svg
        className="text-white dark:text-black"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 225 43"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="m159.363 27.886-1.239 8.754h6.297l1.55-10.955a8.612 8.612 0 0 0-2.267.234c-1.406.326-2.738.949-3.829 1.63-.18.113-.351.226-.512.337Zm.484-3.428a14.886 14.886 0 0 1 3.243-1.183 11.211 11.211 0 0 1 3.262-.288l1.977-13.976h4.994l.652-4.451H157.69l-.651 4.45h4.994l-2.186 15.448Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="m134.568 36.64 4.56-32.08h14.765l-.652 4.45h-8.196l-1.195 8.577h6.948l-.705 4.94h-6.948l-1.357 9.77h8.196l-.651 4.343h-14.765ZM110.345 36.64l4.505-32.08h11.399c2.027 0 3.511.488 4.452 1.465.94.941 1.284 2.262 1.031 3.963l-.489 3.637a8.748 8.748 0 0 1-.814 2.605 6.04 6.04 0 0 1-1.465 1.954 5.708 5.708 0 0 1-1.738.923 6.545 6.545 0 0 1-1.737.38l-.054.055c.615 0 1.194.072 1.737.217a3.576 3.576 0 0 1 1.574.868c.507.47.887 1.122 1.14 1.954.254.796.29 1.792.109 2.986l-.543 4.397c-.253 1.737-.76 3.094-1.52 4.07a6.079 6.079 0 0 1-2.877 2.064c-1.122.361-2.443.542-3.962.542h-10.748Zm6.785-3.31h2.931c.543 0 .995-.091 1.357-.272.398-.217.742-.597 1.032-1.14.289-.543.506-1.34.651-2.389l.543-3.8c.217-1.447.09-2.388-.38-2.822-.434-.47-1.05-.706-1.846-.706h-2.714L117.13 33.33Zm2.117-15.091h2.606c.832 0 1.519-.29 2.062-.869.579-.579.959-1.592 1.14-3.04l.489-3.202c.181-1.122.09-1.918-.272-2.389-.362-.506-.868-.76-1.52-.76h-3.094l-1.411 10.26Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M202.751 42.069c11.992 0 21.713-9.722 21.713-21.713C224.464 8.364 214.066 0 203.43 0c-6.046 0-11.535 2.714-15.02 5.59-.442.16-1.061.26-1.706.364-.941.152-1.936.313-2.517.686l-.003-.002c-1.034.665-1.905 1.498-2.777 2.333-.561.536-1.122 1.073-1.728 1.567-1.243 1.013-2.249 1.15-3.634 1.25-.161.013-.211.183-.092.286 1.325 1.158 3.075 2.127 4.817 2.161-2.855 3.498-5.001 3.327-7.59 3.121-1.373-.11-2.872-.229-4.666.186-5.192 1.199-8.999 4.612-11.882 8.676-.067.094.04.207.154.165l.115-.043a15.48 15.48 0 0 1 1.536-1.091c1.284-.803 2.892-1.565 4.653-1.974 1.764-.41 3.727-.474 5.664.186 1.953.665 3.767 2.029 5.271 4.285l-1.574 1.049.017.014c.603.5 1.228 1.018 1.631 1.316 1.986 1.468 4.872 1.732 7.284.926.121-.04.228.083.147.175-1.309 1.455-3.002 2.521-5.08 2.327-.204-.019-.406-.04-.608-.06-.98-.102-1.938-.202-2.92-.044-1.108.179-1.925.738-2.55 1.553-.063.083.022.196.131.172 1.358-.301 2.858.498 4.1 1.16h.001c.25.134.49.262.716.374.459.227.904.476 1.349.724.212.12.424.238.638.354 1.73.943 5.026.956 7.007-.034.243-.121.48-.243.713-.363 1.039-.534 1.99-1.022 2.89-1.178.162.152.326.301.493.448-.643 1.074-1.182 1.587-2.245 2.596l-.016.016c-.63.597-1.846 1.199-3.26 1.297-.121.008-.17.147-.069.207 2.51 1.476 6.909 2.129 10.353 2.129 2.635 0 5.099-.337 7.364-.947.728.074 1.467.112 2.214.112Zm.679-40.712c-10.873 0-19.678 8.805-19.678 19.677 0 2.388.434 4.68 1.205 6.794v.257l.209.239c2.882 7.257 9.97 12.388 18.264 12.388 10.872 0 19.677-8.805 19.677-19.678 0-10.872-8.805-19.677-19.677-19.677Zm-16.001 13.29a17.146 17.146 0 0 0-1.202 5.26l5.444 1.882 3.632 9.37-3.254 2.79c3.046 2.684 7.017 4.31 11.381 4.31 4.509 0 8.616-1.737 11.69-4.577l-8.58 3.663-5.456-5.682 6.739-7.582 9.21 1.137-1.502 8.072a17.233 17.233 0 0 0 5.111-11.164l-1.662-.415-3.109-8.759.706-3.05a17.271 17.271 0 0 0-6.605-4.809l-4.404-.56-1.964-.723h-.174a17.173 17.173 0 0 0-10.451 3.542l-1.11 1.788-4.44 5.507Zm23.866-3.91-6.971-6.5-9.542 3.429.329 9.255 10.477 1.978 5.707-8.163Z"
          clipRule="evenodd"
        />
        <path
          fill="#2E3641"
          d="M223 21c0 10.866-8.809 19.675-19.675 19.675-10.866 0-19.675-8.809-19.675-19.675 0-10.866 8.809-19.675 19.675-19.675C214.191 1.325 223 10.134 223 21Z"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M223.107 21.034c0-10.872-8.805-19.677-19.677-19.677-10.873 0-19.678 8.805-19.678 19.677a19.643 19.643 0 0 0 1.205 6.794v.257l.174.198c2.65 6.685 8.858 11.572 16.287 12.327-7.414-.753-13.606-5.623-16.252-12.286l-.035-.04a19.797 19.797 0 0 1-1.379-7.25c0-10.867 8.81-19.677 19.678-19.677 10.867 0 19.677 8.81 19.677 19.677ZM203.43 40.712c10.782 0 19.531-8.66 19.675-19.407-.144 10.742-8.898 19.407-19.675 19.407Zm-17.203-20.805a17.147 17.147 0 0 1 1.202-5.26l4.44-5.507 1.11-1.788A17.173 17.173 0 0 1 203.43 3.81h.174l1.964.723 4.404.56a17.264 17.264 0 0 1 6.605 4.809l-.706 3.05 3.109 8.759 1.662.415a17.233 17.233 0 0 1-5.111 11.164l1.502-8.072-9.21-1.137-6.739 7.582 5.456 5.682 8.581-3.663a17.176 17.176 0 0 1-11.691 4.576c-4.364 0-8.335-1.625-11.381-4.309l3.254-2.79-3.632-9.37-5.444-1.882Zm18.097-15.67 6.971 6.499-5.707 8.163-10.477-1.978-.329-9.255 9.542-3.43Z"
          clipRule="evenodd"
        />
        <path
          className="fill-outer-space dark:fill-white"
          fill="#ffffff"
          d="M85.598 37 95.832 5h9.042l1.083 32h-7.039l-.108-5.902h-4.657L92.367 37h-6.769Zm9.043-9.042h4.494l.27-17.381-4.764 17.38ZM62.753 37l4.549-32h11.695c2.13 0 3.664.487 4.602 1.462.975.938 1.318 2.256 1.03 3.953l-.65 4.98c-.253 1.698-.921 3.123-2.004 4.278-1.047 1.155-2.527 1.823-4.44 2.004 1.191.18 2.004.595 2.437 1.245.433.614.74 1.625.92 3.032L82.354 37H75.37l-.758-10.613a9.696 9.696 0 0 0-.216-1.57c-.109-.469-.271-.83-.488-1.083-.18-.288-.45-.433-.812-.433h-1.84L69.304 37h-6.552Zm9.097-18.03h2.599c.938 0 1.678-.271 2.22-.813.541-.541.902-1.462 1.083-2.761l.541-3.844c.18-1.155.09-1.968-.27-2.437-.361-.47-.885-.704-1.57-.704h-3.141L71.85 18.97ZM46.492 37 50.39 9.44H45.41l.65-4.44h16.243l-.65 4.44h-4.981L52.773 37h-6.281ZM19.516 37l7.093-16.46L24.335 5h6.28l1.3 10.667L36.192 5h6.606L36.03 20.54 38.142 37H31.86l-1.408-10.667L26.23 37h-6.714ZM1 37 5.548 5h14.728l-.65 4.44H11.45l-1.191 8.555h6.93l-.703 4.927H9.555l-1.354 9.746h8.176L15.727 37H1Z"
        />
      </svg>
    </div>
  );
}
