import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/react";
import { emitter } from "@lobby/core/shared";
import { useIntlT } from "@lobby/ocb-intl";
import { type TLocale, localesMap } from "@shared/lib";
import { clsx } from "clsx";
import { useEffect } from "react";
import { flagIcons } from "./flag-icons";

interface ILanguageSelectorProps {
  className?: string;
  orientation?: "top" | "bottom";
}

export function LanguageSelector({ className, orientation = "bottom" }: ILanguageSelectorProps) {
  const { intl } = useIntlT();

  const locale = intl.locale as TLocale;

  function handleLanguageChange(locale: TLocale) {
    emitter.emit("SET_LOCALE", locale);
    localStorage.setItem("locale", locale);
  }

  useEffect(() => {
    intl.locale = localStorage.getItem("locale") ?? "en";
  }, [intl, locale]);

  return (
    <Listbox value={locale} onChange={handleLanguageChange}>
      {({ open }) => (
        <div
          className={clsx(
            className,
            "relative flex items-center rounded-rounded p-2.5",
            open && "bg-athens-gray dark:bg-bright-gray",
          )}
        >
          <ListboxButton className="flex items-center">
            <div className="mr-1 flex size-5 justify-center overflow-hidden rounded-full">
              {flagIcons[locale]}
            </div>
            <span className="mr-1.5 font-medium text-sm capitalize">{localesMap[locale].name}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="7"
              fill="none"
              viewBox="0 0 7 7"
            >
              <path
                className="fill-outer-space dark:fill-white"
                d="M4.366 6a1 1 0 0 1-1.732 0L.469 2.25a1 1 0 0 1 .866-1.5h4.33a1 1 0 0 1 .866 1.5L4.366 6Z"
              />
            </svg>
          </ListboxButton>
          <ListboxOptions
            anchor={{
              to: orientation === "top" ? "top start" : "bottom start",
              offset: "-0.625rem",
              gap: "1rem",
            }}
            transition
            className={`z-[1000] rounded-rounded border-1 border-shark/80 bg-mercury px-4 py-3 font-bold transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 lg:p-1.5 dark:bg-bright-gray ${orientation === "top" ? "origin-top" : "origin-bottom"}`}
          >
            <div className="flex flex-col gap-4 font-medium text-sm lg:gap-0">
              {Object.values(localesMap).map((lang) => (
                <ListboxOption
                  key={lang.code}
                  value={lang.code}
                  className="flex cursor-default items-center gap-1 rounded-rounded lg:px-3 lg:py-1.5 lg:data-[focus]:bg-keppel lg:data-[focus]:text-white lg:dark:data-[focus]:bg-java lg:dark:data-[focus]:text-black"
                >
                  <div className="flex size-5 justify-center overflow-hidden rounded-full">
                    {flagIcons[lang.code as TLocale]}
                  </div>
                  {lang.name}
                </ListboxOption>
              ))}
            </div>
          </ListboxOptions>
        </div>
      )}
    </Listbox>
  );
}
