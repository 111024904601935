import { PlayerBalance } from "@features/player/player-balance";
import { Player } from "@lobby/core/entities";
import { useModal } from "@lobby/core/shared";
import { SVGIcon } from "@shared/ui";
import { Skeleton } from "@shared/ui/skeleton";

export function PlayerInfo() {
  const { open: openProfile } = useModal("profile");

  const { data: player } = Player.usePlayer();
  const { isPending: isBalancePending } = Player.useBalances();

  const playerName = player?.displayableName as string;

  return (
    <div className="font-bold lg:font-medium text-2xs lg:text-sm">
      <button className="text-right" type="button" onClick={openProfile}>
        <div className="flex items-center gap-1">
          <div>
            <div className="truncate max-w-28 lg:max-w-36" title={playerName}>
              {playerName}
            </div>
            {isBalancePending ? (
              <Skeleton className="bg-silver h-3 w-20 lg:h-5 lg:w-28" />
            ) : (
              <PlayerBalance />
            )}
          </div>
          <SVGIcon name="player" className="lg:size-5.5 size-3.5" />
        </div>
      </button>
    </div>
  );
}
