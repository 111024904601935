import { Game } from "@lobby/core/entities";
import { range } from "@lobby/core/shared";
import { useGroupedGameList } from "../lib";
import { GameList } from "./index";

interface IGameListsProps {
  className?: string;
}

export function GameLists({ className }: IGameListsProps) {
  const { isPending, isFetching } = Game.useList();

  const gameList = useGroupedGameList();

  return (
    <div className={className}>
      <div className="lg:gap-5 flex flex-col gap-3 *:lg:p-5 *:p-3 *:rounded-rounded *:bg-athens-gray *:dark:bg-outer-space">
        {isPending || isFetching
          ? range(3)((idx) => <GameList.Skeleton key={idx} />)
          : gameList.map(({ type, label, id, games }) => {
              if (!label) {
                return null;
              }

              return (
                <GameList
                  key={id.toString()}
                  items={games}
                  expandable={gameList.length > 1}
                  expanded={gameList.length === 1}
                  categoryId={id}
                  type={type}
                  label={label}
                />
              );
            })}
      </div>
    </div>
  );
}
