import { Coupon } from "@features/coupon";
import { GameHistory } from "@lobby/core/entities";
import { useInfiniteScroll, useMobile } from "@lobby/core/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { SVGIcon } from "@shared/ui";
import { Spin } from "@shared/ui/spin";
import { getMobileSectionScrollContainer } from "@widget/profile-modal/lib";
import { useMemo } from "react";
import { NoDataPlaceholder } from "../../no-data-placeholder";

import "./styles.css";

interface IBetHistoryListProps {
  status?: "outcome" | "cancelled" | "cashout" | "created" | "non-created";
}

export function BetHistoryList({ status }: IBetHistoryListProps) {
  const isMobile = useMobile();
  const { data, fetchNextPage, isFetchingNextPage, isLoading } =
    GameHistory.useSportBetHistory(status);

  const scrollContainer = isMobile ? getMobileSectionScrollContainer() : null;

  const scrollRef = useInfiniteScroll(fetchNextPage, scrollContainer);

  const { $t } = useTranslate();
  const betHistory = useMemo(() => data?.pages.flatMap((page) => page.result?.data) ?? [], [data]);

  const isBetHistoryExist = betHistory.length > 0;

  return (
    <div
      className="lg:overflow-y-auto min-h-0 h-full max-h-full scrollbar-thin custom-scrollbar gutter-stable mobile-only:relative"
      ref={scrollRef}
    >
      {!isBetHistoryExist && !isFetchingNextPage && !isLoading && (
        <NoDataPlaceholder
          topText={
            status === "created"
              ? $t({ defaultMessage: "You have no active bets" })
              : $t({ defaultMessage: "You have no COMPLETED bets" })
          }
          bottomText={status === "created" ? $t({ defaultMessage: "Place a new bid" }) : undefined}
          icon={<SVGIcon name="searchTicket" className="w-28" />}
        />
      )}

      {isLoading && (
        <div className="absolute inset-0 flex-center">
          <Spin />
        </div>
      )}

      {isBetHistoryExist && betHistory.map((item, idx) => <Coupon key={idx} {...item} />)}

      {isFetchingNextPage && (
        <div className="flex items-center justify-center my-2 h-10">
          <SVGIcon className="*:size-5 text-keppel animate-spin" name="reload" />
        </div>
      )}
    </div>
  );
}
