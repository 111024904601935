import { Player, usePlayerMoneyFormatter } from "@lobby/core/entities";
import { useModal } from "@lobby/core/shared";
import { TakeBonusButtonView } from "./take-bonus-button.view";

interface ITakeBonusButtonProps {
  className?: string;
  data: {
    amount: number;
    type: string;
  };
}

function TakeBonusButton({ className, data }: ITakeBonusButtonProps) {
  const { open: openTakeBonusModal } = useModal("take-bonus");
  const formatMoney = usePlayerMoneyFormatter();

  const formattedAmount = formatMoney(data.amount);

  function handleClick() {
    openTakeBonusModal();
  }

  return (
    <TakeBonusButtonView
      className={className}
      type={data.type}
      amount={formattedAmount}
      onClick={handleClick}
    />
  );
}

export function TakeBonusButtonContainer({ className }: { className?: string }) {
  const { data } = Player.useBalances();
  const bonus = data?.bonus as {
    amount: number;
    type: string;
  };

  return bonus && <TakeBonusButton className={className} data={bonus} />;
}
