import { GameCard } from "@entities/game";
import { useNavigateToCategory } from "@features/category/category-button";
import { CategoryIcon } from "@features/category/category-icon";
import type { Output } from "@lobby/api-client/src/codegen";
import { range } from "@lobby/core/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { Button } from "@shared/ui";
import { Pagination } from "@shared/ui/pagination";
import { clsx } from "clsx";
import { memo, useLayoutEffect, useRef, useState } from "react";

interface IGameListProps {
  className?: string;
  expanded?: boolean;
  expandable?: boolean;
  categoryId: string | number;
  type: string;
  label: string;
  items: Output<"Game.getList">["data"] | undefined;
}

export const GameList = memo(function GameList({
  className,
  items,
  expanded,
  expandable,
  categoryId,
  type,
  label,
}: IGameListProps) {
  const { $t } = useTranslate();
  const navigateToCategory = useNavigateToCategory();
  const [itemsCountToRender, setItemsCountToRender] = useState(10);
  const [isContentOverflow, setIsContentOverflow] = useState(false);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollComponentRef = useRef<HTMLDivElement>(null);
  const itemsCountRef = useRef(0);

  itemsCountRef.current = items?.length ?? 0;

  const hasIcon = type !== "search" && type !== "provider";

  useLayoutEffect(() => {
    const checkForContentOverflow = () => {
      const scrollContainer = scrollContainerRef.current;
      const scrollComponent = scrollComponentRef.current;

      if (scrollContainer && scrollComponent) {
        if (!expandable) {
          return setIsContentOverflow(false);
        }

        const isContentOverflow = scrollComponent.scrollWidth > scrollContainer.scrollWidth;
        setIsContentOverflow(isContentOverflow);
      }
    };

    checkForContentOverflow();
    window.addEventListener("resize", checkForContentOverflow);
    return () => window.removeEventListener("resize", checkForContentOverflow);
  }, [items, expandable]);

  useLayoutEffect(() => {
    const scrollListener = (ev: Event) => {
      ev.stopPropagation();

      const { scrollWidth, scrollLeft, clientWidth } = ev.currentTarget as HTMLDivElement;
      if (Math.abs(scrollWidth - clientWidth - scrollLeft) < 1) {
        setItemsCountToRender((prev) => Math.min(prev + 10, itemsCountRef.current));
      }
    };

    scrollComponentRef.current?.addEventListener("scroll", scrollListener);
    return () => scrollComponentRef.current?.removeEventListener("scroll", scrollListener);
  }, []);

  const itemsToRender = expanded ? items : items?.slice(0, itemsCountToRender);

  return (
    <div className={className}>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-1.5 text-keppel dark:text-java">
          {hasIcon && <CategoryIcon className="lg:text-xl" id={categoryId} />}
          <span className="font-bold text-xs uppercase lg:text-xl">{label}</span>
        </div>

        {expandable && isContentOverflow && (
          <div className="flex items-center gap-5">
            <Pagination className="mobile-only:hidden" componentRef={scrollComponentRef.current} />
            <Button onClick={() => navigateToCategory(categoryId)}>
              {$t({ defaultMessage: "Show all" })}
            </Button>
          </div>
        )}
      </div>

      <div className="mt-3 lg:mt-5" ref={scrollContainerRef}>
        <div
          className={clsx(
            "peer grid gap-x-2 gap-y-5 empty:hidden lg:gap-x-5 lg:gap-y-10",
            expanded
              ? "grid-cols-[repeat(auto-fill,minmax(7rem,1fr))] lg:grid-cols-[repeat(auto-fill,minmax(9rem,1fr))]"
              : "scrollbar-thin snap-x snap-mandatory grid-flow-col grid-cols-[repeat(auto-fill,7rem)] overflow-x-auto *:w-28 lg:grid-cols-[repeat(auto-fill,9rem)] lg:*:w-36",
          )}
          ref={scrollComponentRef}
        >
          {itemsToRender
            ? itemsToRender.map(
                (
                  { id, pic, providerName, name, stringId, isFavourite, isWalletIntegration },
                  idx,
                ) => (
                  <GameCard
                    key={idx}
                    imgSrc={pic}
                    title={name}
                    provider={providerName}
                    id={id}
                    gameStringId={stringId}
                    isFavourite={isFavourite}
                    showWalletIntegrationWarning={isWalletIntegration}
                  />
                ),
              )
            : range(18)((idx) => <GameCard.Skeleton key={idx} />)}
        </div>
        <div className="hidden text-xs peer-empty:block lg:text-xl">
          {$t({ defaultMessage: "No games in this category" })}
        </div>
      </div>
    </div>
  );
});
