import { Lobby } from "@lobby/core/entities";
import { formatTime, useFortuneWheelCountdown } from "@lobby/core/shared";
import { memo } from "react";
import { WHEEL_RADIUS as R } from "../lib";

export const Timer = memo(function Timer() {
  const { data } = Lobby.useLobby();

  const countdown = useFortuneWheelCountdown(data?.fortuneWheel?.nextSpinTime as number);

  return (
    <text
      className="fill-java font-commissioner font-extrabold text-2xl [text-shadow:0_0_24px_#13c7bc]"
      alignmentBaseline="middle"
      textAnchor="middle"
      x={R}
      y={R}
    >
      {formatTime(countdown)}
    </text>
  );
});
